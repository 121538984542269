
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButton, modalController, menuController, IonButtons } from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, onMounted, computed } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { Calendar } from "v-calendar";
import { openToast } from "@/services/toast";
import { dateFormat } from "@/services/utils";

import apiAppuntamenti from "@/services/appuntamenti_squadre";
import apiRapportini from "@/services/rapportini";

import ProgrammaLavoroDetail from "@/components/programma_lavoro/ProgrammaLavoroDetail.vue";
import DettaglioRapportino from "@/components/rapportini/DettaglioRapportino.vue";

export default {
    name: "ProgrammaLavoro",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        Calendar,
    },
    setup() {
        const loading = ref(false);
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;

        const appuntamentiToShow = ref([]);

        const openMenu = () => {
            menuController.open("app-menu");
        };

        /**
         *
         * ! Open rapportino detail
         *
         */
        async function openRapportinoDetail(rapportino) {
            const modal = await modalController.create({
                component: DettaglioRapportino,
                componentProps: {
                    data: rapportino,
                },
            });
            return modal.present();
        }

        async function controlloAppuntamenti(appuntamentiVisualizzati) {
            if (appuntamentiVisualizzati.length != 0) {
                //Estraggo gli id degli appuntamenti e cerco i rapportini per questi appuntamenti_id
                const appuntamentiId = [];
                appuntamentiVisualizzati.forEach((el) => {
                    appuntamentiId.push(el.appuntamenti_id);
                });

                if (appuntamentiId.length != 0) {
                    const ids = `(${appuntamentiId.toString()})`;

                    try {
                        const res = await apiRapportini.getRapportiniAppuntamenti(ids, userID);
                        if (res.status === 0) {
                            if (res.data.length != 0) {
                                appuntamentiVisualizzati.forEach((appuntamento) => {
                                    appuntamento.rapportino_creato = false;
                                    //Se l'appuntamento è legato ad un rapportino lo marco con rapportino_creato
                                    const appuntamentoId = appuntamento.appuntamenti_id;
                                    const hasRapportino = res.data.some((rapportino) => rapportino.rapportini_appuntamento_id === appuntamentoId);

                                    if (hasRapportino) {
                                        appuntamento.rapportino_creato = true;
                                    } else {
                                        appuntamento.rapportino_creato = false;
                                    }
                                });
                            } else {
                                appuntamentiVisualizzati.forEach((appuntamento) => {
                                    appuntamento.rapportino_creato = false;
                                });
                            }
                        } else {
                            openToast("Si è verificato un errore durante il controllo degli appuntamenti", "toast_danger");
                        }
                    } catch (error) {
                        console.log(error);
                        openToast("Si è verificato un errore durante il controllo degli appuntamenti", "toast_danger");
                    }
                }
            }
        }
        /**
         * ! Passing appuntamento object ProgrammaLavoroDetail page
         */
        async function openDetailModal(appuntamento) {
            const modal = await modalController.create({
                component: ProgrammaLavoroDetail,
                componentProps: {
                    data: appuntamento,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    openRapportinoDetail(detail.data);
                    //openToast("Rapportino salvato con successo", "toast_success");
                } else {
                    controlloAppuntamenti(appuntamentiToShow.value);
                }
            });

            return modal.present();
        }

        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                return cliente.customers_company;
            } else if (cliente.customers_name && !cliente.customers_last_name) {
                return `${cliente.customers_name}`;
            }
            return `${cliente.customers_name} ${cliente.customers_last_name}`;
        };

        const indirizzoCliente = (cliente) => {
            const city = cliente.customers_city ? cliente.customers_city : "";
            const province = cliente.customers_province ? "(" + cliente.customers_province + ")" : "";
            return `${city} ${province}`;
        };

        /**
         * ! Get all appuntamenti
         */
        const appuntamenti = ref([]);
        async function loadAppuntamenti() {
            loading.value = true;
            try {
                const res = await apiAppuntamenti.getAppuntamenti(userID);
                if (res.status === 0) {
                    appuntamenti.value = res.data;
                    // Controllo se gli appuntamenti di oggi hanno già un rapportino creato per mostrare spunta
                    if (appuntamentiToShow.value.length != 0) {
                        controlloAppuntamenti(appuntamentiToShow.value);
                    }
                } else {
                    openToast("Errore durante la richiesta degli appuntamenti", "toast_danger");
                }
            } catch (error) {
                appuntamenti.value = [];
                openToast("Errore durante la richiesta degli appuntamenti", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        /**
         * ! Set calendar data
         */
        const date = ref(new Date());
        const selectedDay = ref(null); // Add state to store selected day

        const setAttributes = computed(() => {
            return appuntamenti.value.map((appuntamento) => ({
                key: `appuntamento.${appuntamento.appuntamenti_id}`,
                highlight: {
                    color: "blue",
                    fillMode: "solid",
                },
                dates: appuntamento.appuntamenti_giorno,
                customData: appuntamento,
            }));
        });

        /**
         * ! Mostra solo gli appuntamenti del giorno selezioanto
         */
        const dayClicked = (day) => {
            selectedDay.value = day;
            appuntamentiToShow.value = [];
            //appuntamentiToShow.value = [...day.attributes];
            appuntamentiToShow.value = day.attributes.length == 0 ? [] : day.attributes.map((appuntamento) => appuntamento.customData);

            // Controllo se gli appuntamenti di oggi hanno già un rapportino creato per mostrare spunta
            if (appuntamentiToShow.value.length != 0) {
                controlloAppuntamenti(appuntamentiToShow.value);
            }
        };

        onMounted(() => {
            //GET APPUNTAMENTI DATA
            loadAppuntamenti();
        });

        return {
            loading,
            add,
            refresh,
            openDetailModal,
            menu,
            openMenu,
            //Appuntamenti
            loadAppuntamenti,
            dateFormat,
            appuntamenti,
            Calendar,
            date,
            selectedDay,
            dayClicked,
            setAttributes,
            appuntamentiToShow,
            riferimentoCliente,
            indirizzoCliente,
        };
    },
};
